.netBtn {
  font-size: 1em;
  padding: 0 10px;
  min-height: 50px;
  max-height: 50px;
  border-radius: 2px;
  border: 1px solid #b5b5b5;
  margin: 2px;
}

.netBtn:hover {
  transform: translateY(0) scale(1.02);
  background: rgba(0, 0, 0, 0);
  box-shadow: inset 0 0 0 3px #ffc107;
}

.netBtn:focus {
  border: 1px solid white;
  outline: 0;
}
